const SeatImageList = [
    {
        publicId: "rvxhgpfctcbrfbil76g2.png",
        urlSuffix: "lakers-staples-center-section-114-wide"
    },
    /* {
        publicId: "rie56zhvuhmu1hzvveiq.png",
        urlSuffix: "lakers-staples-center-section-114-close"
    }, */
    {
        publicId: "LakeshowTix2020-8_mzupnh.png",
        urlSuffix: "lakers-court-warm-ups"
    },
    {
        publicId: "LakeshowTix2020-9_jdoqtz.png",
        urlSuffix: "staples-center-section-114-row-18"
    },
    {
        publicId: "LakeshowTix2020-7_x2okxk.png",
        urlSuffix: "staples-center-section-114-row-18-chairs-behind"
    },
    {
        publicId: "LakeshowTix2020-6_nz3o8q.png",
        urlSuffix: "staples-center-section-114-row-18-chairs-above-side"
    },
    {
        publicId: "LakeshowTix2020-5_llztjj.png",
        urlSuffix: "staples-center-section-114-row-18-above-chair-open"
    },
    {
        publicId: "LakeshowTix2020-3_qhn1fe.png",
        urlSuffix: "staples-center-section-114-row-18-above-chair-closed-side"
    },
    {
        publicId: "LakeshowTix2020-2_irntio.png",
        urlSuffix: "staples-center-section-114-row-18-behind-chair-court"
    },
    {
        publicId: "LakeshowTix2020-18_kaaydx.png",
        urlSuffix: "staples-center-section-114-row-18-side-all-chairs"
    },
    {
        publicId: "LakeshowTix2020-15_ugi1au.png",
        urlSuffix: "staples-center-section-114-row-18-side-some-chairs"
    },
    {
        publicId: "LakeshowTix2020-17_q32r5d.png",
        urlSuffix: "staples-center-section-114-row-18-side-all-chairs-close"
    },
    {
        publicId: "LakeshowTix2020-35_xymtab.png",
        urlSuffix: "staples-center-section-114-row-18-cameraman"
    },
    {
        publicId: "LakeshowTix2020-12_flhzjg.png",
        urlSuffix: "staples-center-section-114-row-18-view-warm-ups"
    },
    {
        publicId: "LakeshowTix2020-13_tmix2l.png",
        urlSuffix: "staples-center-section-114-row-18-above-chairs-view"
    },
    {
        publicId: "LakeshowTix2020-14_fk7y4p.png",
        urlSuffix: "staples-center-section-114-row-18-side-all-chairs-above"
    },
    {
        publicId: "LakeshowTix2020-11_as8bi1.png",
        urlSuffix: "staples-center-section-114-row-18-cameraman-angle-wide"
    },
    {
        publicId: "LakeshowTix2020-10_wydsen.png",
        urlSuffix: "staples-center-section-114-row-18-cameraman-angle-close"
    },
    {
        publicId: "LakeshowTix2020-49_wamllx.png",
        urlSuffix: "lakers-celebrate-win-staples-center"
    },
    {
        publicId: "LakeshowTix2020-48_oyupqv.png",
        urlSuffix: "lakers-celebrate-win-staples-center-jumbotron"
    },
    {
        publicId: "LakeshowTix2020-44_w32nji.png",
        urlSuffix: "lebron-james-behind-the-back"
    },
    {
        publicId: "LakeshowTix2020-27_fytm0m.png",
        urlSuffix: "alex-caruso-driving-layup"
    },
    {
        publicId: "LakeshowTix2020-40_tqv8zd.png",
        urlSuffix: "lebron-james-driving-layup"
    },
    {
        publicId: "LakeshowTix2020-32_eylvml.png",
        urlSuffix: "javale-mcgee-monster-dunk"
    },
]

export default SeatImageList


/*
    "LakeshowTix2020-46_g0auu6.png",
    "LakeshowTix2020-47_yumrqv.png",
    "LakeshowTix2020-45_x9wdnj.png",
    "LakeshowTix2020-41_qhzaoe.png",
    "LakeshowTix2020-34_zoiy7y.png",
    "LakeshowTix2020-43_jk8ql2.png",
    "LakeshowTix2020-42_tdu5lc.png",
    "LakeshowTix2020-39_yzcp8l.png",
    "LakeshowTix2020-38_a4o31v.png",
    "LakeshowTix2020-37_udjo3f.png",
    "LakeshowTix2020-36_zhuco2.png",
    "LakeshowTix2020-33_ub3zbc.png",
    "LakeshowTix2020-31_dchjzh.png",
    "LakeshowTix2020-30_qtblrk.png",
    "LakeshowTix2020-29_olas4o.png",
    "LakeshowTix2020-28_kgveog.png",
    "LakeshowTix2020-26_idzvkk.png",
    "LakeshowTix2020-25_lsajt7.png",
    "LakeshowTix2020-24_cnfxi1.png",
    "LakeshowTix2020-23_g5zz9r.png",
    "LakeshowTix2020-44_w32nji.png",
    "LakeshowTix2020-16_glgrz4.png",
    "LakeshowTix2020-27_fytm0m.png",
    "LakeshowTix2020-40_tqv8zd.png",

    "LakeshowTix2020-32_eylvml.png",






*/